:global #okta-sign-in .o-form .input-fix {
  border-radius: 0;
}

:global #okta-sign-in .focused-input {
  box-shadow: none;
}

:global #okta-sign-in .link.help:focus {
  box-shadow: none;
}

:global #okta-sign-in .button {
  border-radius: 0;
}

:is(:global #okta-sign-in.auth-container input[type="button"], :global #okta-sign-in.auth-container input[type="submit"]) {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

:global #okta-sign-in .beacon-loading:after {
  border-top-color: #222;
}

:global #okta-sign-in.auth-container .button.button-primary.link-button-disabled {
  box-shadow: none;
  border-color: #0000;
}

:global #okta-sign-in.auth-container input[type="submit"]:focus {
  box-shadow: none;
  border-color: #0000;
}

:global #okta-sign-in.auth-container .okta-form-input-field.focused-input {
  border-color: #222;
}

:global #okta-sign-in.auth-container .button-primary {
  border-color: #0000;
}

:global #okta-sign-in.auth-container .button-primary:hover {
  border-color: #0000;
}

.buttonWrapper {
  margin-bottom: 18px;
  padding: 0 18px;
}

.button {
  justify-content: center;
  align-items: center;
  display: flex;
}
