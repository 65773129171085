.loginLinksWrapper {
  display: flex;
  justify-content: space-between;
}

.passwordLoginCTA {
  margin-top: 9px;
  color: #559b5e;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.passwordLoginCTA:hover {
  color: #222;
}

.arrowDown {
  width: 10px;
  height: 10px;
  transition: transform 0.1s ease;
  transform: rotate(0deg);
  margin-left: 5px;
}

.arrowUp {
  transform: rotate(180deg);
}

.internalEmplError {
  color: #cc0000;
}

.internalErrorLink {
  color: #fff;
}

.dealerLinkOnly {
  justify-content: flex-end;
}

.belowContent {
  z-index: -10;
}

.aboveContent {
  z-index: 100;
}

.content {
  z-index: 10;
}

.hide {
  display: none;
}