:global #okta-sign-in .o-form .input-fix {
  border-radius: 0;
}
:global #okta-sign-in .focused-input,
:global #okta-sign-in .link.help:focus {
  box-shadow: none;
}
:global #okta-sign-in .button {
  border-radius: 0;
}
:global #okta-sign-in.auth-container input[type=button],
:global #okta-sign-in.auth-container input[type=submit] {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
}
:global #okta-sign-in .beacon-loading:after {
  border-top-color: #222;
}
:global #okta-sign-in.auth-container .button.button-primary.link-button-disabled,
:global #okta-sign-in.auth-container input[type=submit]:focus {
  box-shadow: none;
  border-color: transparent;
}
:global #okta-sign-in.auth-container .okta-form-input-field.focused-input {
  border-color: #222;
}
:global #okta-sign-in.auth-container .button-primary {
  border-color: transparent;
}
:global #okta-sign-in.auth-container .button-primary:hover {
  border-color: transparent;
}

.buttonWrapper {
  padding: 0 18px;
  margin-bottom: 18px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
}