.loginLinksWrapper {
  justify-content: space-between;
  display: flex;
}

.passwordLoginCTA {
  color: #559b5e;
  cursor: pointer;
  margin-top: 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
}

.passwordLoginCTA:hover {
  color: #222;
}

.arrowDown {
  width: 10px;
  height: 10px;
  margin-left: 5px;
  transition: transform .1s;
  transform: rotate(0);
}

.arrowUp {
  transform: rotate(180deg);
}

.internalEmplError {
  color: #c00;
}

.internalErrorLink {
  color: #fff;
}

.dealerLinkOnly {
  justify-content: flex-end;
}

.belowContent {
  z-index: -10;
}

.aboveContent {
  z-index: 100;
}

.content {
  z-index: 10;
}

.hide {
  display: none;
}
